<template>
  <!-- 企业资质 -->
  <div>
    <ParagraphTitle title="企业资质" />

    <div class="certificate">
      <div
        class="certificate-item"
        v-for="item in certificateList"
        :key="item.title"
      >
        <img v-imgLazy="item.img" :alt="item.title" width="200px"/>
        <span class="certificate-title">{{ item.title }}</span>
      </div>
    </div>

    <!-- <div class="more">
      <img v-imgLazy="moreCertificate" alt="更多证书" />
    </div> -->
  </div>
</template>

<script>
import ParagraphTitle from '@/components/ParagraphTitle'

export default {
  name: 'EnterpriseQualification',
  components: {
    ParagraphTitle
  },

  data() {
    return {
      // 更多证书
      moreCertificate: require('@/assets/images/gengduozhengshu.png'),
      // 证书
      certificateList: [
        {
          img: require('@/assets/images/zs (11).png'),
          title: 'AAA级企业信用等级证书'
        },
        {
          img: require('@/assets/images/zs (12).png'),
          title: 'AAA级系统集成行业诚信单位'
        },
        {
          img: require('@/assets/images/zs (5).png'),
          title: 'AAA级诚信供应商'
        },
        {
          img: require('@/assets/images/zs (6).png'),
          title: 'AAA级诚信经营示范单位'
        },
        {
          img: require('@/assets/images/zs (7).png'),
          title: 'AAA级质量、服务诚信单位'
        },
        {
          img: require('@/assets/images/zs (8).png'),
          title: 'AAA级立信单位'
        },
        {
          img: require('@/assets/images/zs (13).png'),
          title: 'AAA级重合同守信用企业'
        },
        {
          img: require('@/assets/images/zs (14).png'),
          title: 'AAA级资信等级证书'
        },
        {
          img: require('@/assets/images/zs (15).png'),
          title: '环境管理体系认证证书'
        },
        {
          img: require('@/assets/images/zs (16).png'),
          title: '职业健康安全管理体系认证证书'
        },
        {
          img: require('@/assets/images/zs (17).png'),
          title: '质量管理体系认证证书'
        },
        {
          img: require('@/assets/images/zs (1).png'),
          title: '增值电信业务经营许可证'
        },
        {
          img: require('@/assets/images/zs (2).png'),
          title: '增值电信业务经营许可证'
        },
        {
          img: require('@/assets/images/zs (3).png'),
          title: '增值电信业务经营许可证'
        },
        {
          img: require('@/assets/images/zs (4).png'),
          title: '计算机软件著作权登记证书'
        },
        {
          img: require('@/assets/images/zs (9).png'),
          title: '诚信职业经理人证书'
        },
        {
          img: require('@/assets/images/zs (10).png'),
          title: '诚信企业家证书'
        },
        {
          img: require('@/assets/images/zs (18).png'),
          title: '信息系统建设和服务能力等级证书 信息技术服务标准符合性证书'
        },
        {
          img: require('@/assets/images/zs (19).png'),
          title: '信息安全服务资质认证证书'
        },
        {
          img: require('@/assets/images/zs (20).png'),
          title: '中国电子信息行业联合会证书'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.certificate {
  width: 1200px;
  margin: 76px auto 0;
  display: flex;
  flex-wrap: wrap;

  .certificate-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 40px 31px;

    .certificate-title {
      display: inline-block;
      width: 220px;
      word-break: break-all;
      text-align: center;
      line-height: 24px;
    }
  }
}

.more {
  margin-bottom: 46px;
  text-align: center;
}
</style>
