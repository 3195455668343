<template>
  <!-- 公司概况 -->
  <div class="outline">
    <img
      style="width: 100%; height: auto; vertical-align: bottom;"
      src="@/assets/images/gongsigaiyao.png"
      alt="公司概况"
    />

    <!-- tabs -->
    <div class="tab-box">
      <MyTabs class="tab" :tabs="tabsList" @changeTab="changeTab" />
    </div>

    <keep-alive>
      <!-- 公司简介 -->
      <CompanyProfile v-if="activeTab === '公司简介'" />

      <!-- 合作伙伴 -->
      <CooperativePartner v-if="activeTab === '合作伙伴'" />

      <!-- 企业资质 -->
      <EnterpriseQualification v-if="activeTab === '企业资质'" />
    </keep-alive>

    <!-- 版权信息 -->
    <Copyright />
  </div>
</template>

<script>
import MyTabs from '@/components/MyTabs'
import CompanyProfile from './components/CompanyProfile.vue'
import CooperativePartner from './components/CooperativePartner.vue'
import EnterpriseQualification from './components/EnterpriseQualification.vue'
import Copyright from '@/components/Copyright'

export default {
  name: 'OutLine',
  components: {
    MyTabs,
    CompanyProfile,
    CooperativePartner,
    EnterpriseQualification,
    Copyright
  },

  data() {
    return {
      activeTab: '公司简介',
      // tabs 列表
      tabsList: [
        {
          name: '公司简介'
        },
        {
          name: '合作伙伴'
        },
        {
          name: '企业资质'
        }
      ]
    }
  },

  methods: {
    changeTab(name) {
      this.activeTab = name
    }
  }
}
</script>

<style lang="scss" scoped>
.outline {
  position: relative;
}

.tab-box {
  position: relative;
  margin-bottom: 113px;

  .tab {
    bottom: -32px;
  }
}
</style>
