<template>
  <!-- 合作伙伴 -->
  <div>
    <ParagraphTitle title="合作伙伴" />
    <div class="cooperation">
      <img src="@/assets/images/hezuohuoban.png" alt="合作伙伴" />
    </div>
  </div>
</template>

<script>
import ParagraphTitle from '@/components/ParagraphTitle'

export default {
  name: 'CooperativePartner',
  components: {
    ParagraphTitle
  }
}
</script>

<style lang="scss" scoped>
.cooperation {
  width: 1128px;
  margin: 49px auto 67px;
}
</style>
